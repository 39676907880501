import NetworkUtil from "./NetworkUtil"
import Price, { FacilityPrice } from "../types/Price"

export default class PricingAPI {
    public static async getAllPricingForSelect():Promise<{success:boolean,reason?:string,data:{label:string,value:number}[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/allPricesForSelect");
    }
    public static async getAllPricingTypesForSelect():Promise<{success:boolean,reason?:string,data:{label:string,value:number}[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/allPriceTypesForSelect");
    }

    // used in ManageFacility.tsx to retrieve default pricing data for services
    public static async getAllPricing():Promise<{success:boolean,reason?:string,data:Price[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/all");
    }

    public static async newPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/create",body);
    }
    public static async editPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/edit",body);
    }
    public static async queryPricing(body):Promise<{success:boolean,reason?:string,price:Price}>{
        return NetworkUtil.makePost("/api/admin/pricing/details",body);
    }
    public static async filterPricing(filter:{filter:{SelectedPrice}}):Promise<{success:boolean,reason?:string,data:Price[]}>{
        return NetworkUtil.makePost("/api/admin/pricing/filter",filter);
    }

    // used in WalkinForm.tsx to retrieve Standard Facility Service Pricing 
    // used in SamplesReport.tsx to retrieve Upgrade Facility Service Pricing
    public static async getAllFacilityPricesForService(facilityID, serviceIDs, fromWalkin):Promise<{success:boolean,reason?:string,data:FacilityPrice[]}>{
        return NetworkUtil.makeGet("/api/admin/pricing/allPricesForService?facilityID="+facilityID+"&serviceID="+serviceIDs+"&fromWalkin="+fromWalkin);
    }

    // used in src/components/admin/Facility.tsx to retrieve Facility Service Pricing
    // public static async getAllFacilityServicePricing():Promise<{success:boolean,reason?:string,data:FacilityPrice[]}>{
    //     return NetworkUtil.makeGet("/api/admin/pricing/allFacilityServicePricing")
    // }
    // TODO create FacilityServicePricing
    // TODO update FacilityServicePricing
}