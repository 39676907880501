import React from "react";
import {sweetalert} from "../../App";
import Overlay from "../Overlay";
import { buildRow, getLabel, hideModal, showModalNoOutsideClick } from "../../util/FormatUtil";
import { AuthNetEnvironment, HostedForm } from "react-acceptjs";
import { isBlank, isOnlyWhitespace, isValidEmail } from "../../util/ValidationUtil";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import Select from "react-select";
import { FacilityAPI } from "../../network/FacilityAPI";
import DiscountAPI from "../../network/DiscountAPI";
import PaymentAPI from "../../network/PaymentAPI";

interface PaymentFormModalProps{
    selectedServices?
    associatedPrices?
    handlePaymentInfo:(response, totalPayment, lineItems)=> void
    selectedFacility?
    refreshSelectedFacility?
    countries
    states
    allowDiscountEntry?:boolean
    fromWalkin?:boolean
}

interface PaymentFormModalState {
    showLoading:boolean
    totalAmountForServices
    showPaymentProfileInfo?:boolean
    discountCode:string
    discountAmount
    discountApplied?:boolean
    paymentAmount
    ANETCustomerProfile?
    hostedFormResponse?
    paymentObject?
    lineItems?
    changesMade?:boolean
    useProviderInfo?:boolean
    billToInfoVerified?:boolean
    selectedFacility?
    selectedServices?
    associatedPrices?
    fromWalkin?
}

const authData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID,
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_CLIENT_KEY
}
const aNetEnv = process.env.REACT_APP_AUTHORIZE_NET_ENVIRONMENT as AuthNetEnvironment

export class PaymentFormModal extends React.Component<PaymentFormModalProps,PaymentFormModalState>{
    public static ID = "paymentForm"

    constructor(props){
        super(props);
        this.state = {
            showLoading:false,
            totalAmountForServices:null,
            discountCode:"",
            discountAmount:null,
            discountApplied:null,
            paymentAmount:null,
            ANETCustomerProfile:null,
            hostedFormResponse:null,
            paymentObject:null,
            lineItems:null,
            changesMade:false,
            useProviderInfo:false,
            billToInfoVerified:false,
            selectedFacility:{},
            selectedServices: [],
            associatedPrices: []
        }
        this.validateDiscountCode = this.validateDiscountCode.bind(this);
        this.clearPaymentFormState = this.clearPaymentFormState.bind(this);
    }

    clearPaymentFormState = ()=>{
        return this.setState({
            totalAmountForServices: 0,
            discountCode:"",
            discountAmount:null,
            discountApplied:false,
            paymentAmount:"",
            ANETCustomerProfile:null,
            hostedFormResponse:null,
            paymentObject:null,
            lineItems:null,
            changesMade:false,
            useProviderInfo:false,
            billToInfoVerified:false,
            selectedFacility:{},
            selectedServices: [],
            associatedPrices: []
        })
    }

    loadScript(src:string){
        var tag = document.createElement('script');     
        tag.src = src;
        document.body.appendChild(tag);
    }

    componentDidMount(): void {
        $(`#${PaymentFormModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
        this.loadScript(process.env.REACT_APP_AUTHORIZE_NET_LOADSCRIPT);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<PaymentFormModalProps>, nextContext: any): void {
        // if (nextProps !== this.props) { }
        // nextProps is maybe what is causing the issue with totals showing as zero when modal is closed and reopened
        this.setState({
          discountCode: this.state.discountCode !== "" ? this.state.discountCode : "",
          discountAmount: this.state.discountApplied == true && this.state.discountAmount ? this.state.discountAmount : null,
          selectedFacility: nextProps.selectedFacility ? nextProps.selectedFacility : null,
          selectedServices: nextProps.selectedServices ? nextProps.selectedServices : [],
          associatedPrices: nextProps.associatedPrices ? nextProps.associatedPrices : [],
          fromWalkin: nextProps.fromWalkin ? nextProps.fromWalkin : false
        }, () => {
          if (!isOnlyWhitespace(this.state.discountCode) && !isBlank(this.state.discountCode)) {
            this.validateDiscountCode(this.state.discountCode)
          } else {
            this.calculateAssociatedPrices();
          }

          if (this.state.selectedFacility &&
            (!isOnlyWhitespace(this.state.selectedFacility?.ANETCustomerProfileID) && !isBlank(this.state.selectedFacility?.ANETCustomerProfileID)) &&
            (!isOnlyWhitespace(this.state.selectedFacility?.ANETCustomerPaymentProfileID) && !isBlank(this.state.selectedFacility.ANETCustomerPaymentProfileID))
          ) {
            PaymentAPI.getCustomerProfile(
              this.state.selectedFacility.ANETCustomerProfileID,
              this.state.selectedFacility.ANETCustomerPaymentProfileID
            ).then(data => {
              this.setState({ ANETCustomerProfile: data.customerProfile.profile }, () => {
                $('#flexRadio0').prop('checked', true).trigger("click");
              })
            })
          } else {
            this.setState({
              ANETCustomerProfile: {
                customerProfileId: '',
                description: '',
                email: '',
                paymentProfiles: [
                  {
                    billTo: {
                      address: '',
                      city: '',
                      country: '',
                      firstName: '',
                      lastName: '',
                      phoneNumber: '',
                      state: '',
                      zip: ''
                    },
                    customerPaymentProfileId: '',
                    payment: {
                      creditCard: {
                        cardNumber: '',
                        cardType: '',
                        expirationDate: ''
                      }
                    }
                  }
                ],
                profileType: 'regular',
                // shipToList: [
                //     {
                //         address: '',
                //         city: '',
                //         company: '',
                //         country: '',
                //         customerAddressId: '',
                //         faxNumber: '',
                //         firstName: '',
                //         lastName: '',
                //         phoneNumber: '',
                //         state: '',
                //         zip: ''
                //     }
                // ]
              },
              selectedFacility: nextProps.selectedFacility
            })
          }
        })
      // }
    }

    calculateAssociatedPrices() {
      let total = 0;

      // if fromWalkin = true, filter out upgrade pricing types
      // if fromWalkin = false, filter out standard pricing types
      this.state.associatedPrices && this.state.associatedPrices.length &&
      this.state.associatedPrices.length > 0 ?
        this.state.associatedPrices.map((price) => {
          let isFacilityServicePrice:boolean;

          if(this.state.fromWalkin === false && price.PricingType === 'Upgrade'){
            isFacilityServicePrice= this.state.selectedServices?.some(ss => ss?.PricingIDs?.find(id => id === price.PricingID && price.FacilityID === this.state.selectedFacility.ID && price.ServiceID === ss.ID));
            if(isFacilityServicePrice){
              total += parseFloat(price.Cost)
            }
          }
          if(this.state.fromWalkin === true && price.PricingType === 'Standard'){
            isFacilityServicePrice= this.state.selectedServices?.some(ss => ss?.PricingIDs?.find(id => id === price.PricingID && price.FacilityID === this.state.selectedFacility.ID && price.ServiceID === ss.ID));
            if(isFacilityServicePrice){
              total += parseFloat(price.Cost)
            }
          }
        }) : [] ;

      this.setState({
        totalAmountForServices: total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        paymentAmount: total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      })
    }

    validateDiscountCode = async (code) => {
      if (code === "" || this.state.discountApplied) {
        return;
      }

      let response = await DiscountAPI.validateDiscountCode({ code: this.state.discountCode })
      if (!response.success) {
        return sweetalert.fire({ icon: 'error', title: "", text: response.reason }).then(() => {
          this.setState({ discountCode: "" })
        });
      } else {
        sweetalert.fire({ icon: "success", title: "", text: "Discount applied." }).then(() => {
          let finalPayment = 0;
          const discountTotal = parseFloat(response.data.DiscountAmount);
          const totalAmountForServices = parseFloat(this.state.totalAmountForServices.split(',').join(''))
          if (discountTotal < totalAmountForServices) {
            finalPayment = totalAmountForServices - discountTotal;
          }
          this.setState({
            discountApplied: true,
            discountAmount: discountTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            paymentAmount: finalPayment.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          })
        });
      }
    }


    buildLineItems = () => {
      let lineItems = [];
      
      let discountLineItem = {
        name: "Discount: " + this.state.discountCode.substring(0, 20),
        price: this.state.discountAmount
      }

      this.props.selectedServices.forEach((service) => {
        let pricingIDs = service.PricingIDs;
        if (pricingIDs && pricingIDs?.length > 0) {
          pricingIDs.forEach((pricingID) => {
            let facilityServicePrice = this.state.associatedPrices.find(price => price.PricingID === pricingID && price.FacilityID=== this.props.selectedFacility.ID); 
            let isServicePrice = this.props.selectedServices?.some(ss => ss?.PricingIDs?.find(id => id === facilityServicePrice.PricingID && facilityServicePrice.FacilityID === this.state.selectedFacility.ID && facilityServicePrice.ServiceID === ss.ID));  

            if (facilityServicePrice && isServicePrice) {
              let lineItem = {
                name: facilityServicePrice.Name.substring(0, 15),
                price: facilityServicePrice.Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }

              switch (facilityServicePrice.PricingType) {
                case 'Upgrade':
                  if (this.props.fromWalkin === false && isServicePrice) { 
                    lineItems.push(lineItem); 
                  }
                  break;
                case 'Standard':
                  if (this.props.fromWalkin === true && isServicePrice) { 
                    lineItems.push(lineItem); 
                  }
                  break;
              }
              return lineItems;
            }
          })
          if (this.state.discountApplied === true) { lineItems.push(discountLineItem); }
        }
        // else {
        //   console.log(' No Pricing to assign for lineItem')
        //   return lineItems 
        // }
        return lineItems
      });

      return lineItems;
    }

    handleHostedFormResponse = async (hostedFormResponse)=>{
        this.setState({hostedFormResponse: hostedFormResponse, paymentObject:hostedFormResponse}, ()=>{
            $('#flexRadioEntered').prop('checked',true).trigger("click");
        });
    }
    

    displayPaymentProfileOptions() {
        if (this.state.ANETCustomerProfile === null) { return; }
        let paymentOptions = this.state.ANETCustomerProfile?.paymentProfiles;
        let cardOnFile = paymentOptions && 
                         paymentOptions.length &&
                         paymentOptions.length > 0 && 
                         paymentOptions[0].customerPaymentProfileId !== "";
        return (
            <div className="" id="profile_payment_options">
                {!cardOnFile && 
                 this.state.hostedFormResponse !== null && 
                 this.state.ANETCustomerProfile?.customerProfileId === "" &&
                    <div className="col-sm-12">
                        <div className={"form-group row"}>
                            <div className={"col-12 pr-0"} data-toggle={'tooltip'} data-placement={'top'} title={'SaveCard'}>
                                <div className="form-check float-right" style={{ paddingTop: '0.25rem' }}>
                                    <input className="form-check-input" type="checkbox" 
                                        checked={this.state.showPaymentProfileInfo ? true : false} 
                                        id="saveCardInfoCheck" 
                                        onChange={() => this.setState({showPaymentProfileInfo: !this.state.showPaymentProfileInfo})} />
                                    <label className="form-check-label" htmlFor="saveCardInfoCheck">
                                        Save Card Information
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { cardOnFile && 
                <div className="col-sm-12">
                    <div className={""}>Card On File:</div>
                    { paymentOptions.map((pt, ndx) => {
                            return (
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="radio" name={"flexRadioGroup"} id={"flexRadio" + ndx}
                                        onClick={(e) => {
                                            this.setState({paymentObject:this.state.ANETCustomerProfile})
                                        }} />
                                    <label className="form-check-label" htmlFor={"flexRadio" + ndx}>
                                        {pt.payment.creditCard.cardNumber}
                                    </label>
                                </div>
                            )
                        })
                    }
                    {
                        this.state.hostedFormResponse !== null &&
                        !cardOnFile && 
                        <div className="" id="profile_entered_payment_options">
                            <div className={""}>Entered:</div>
                            <div className={"form-check"}>
                                <input
                                    className={"form-check-input"}
                                    type="radio" name={"flexRadioGroup"} id={"flexRadioEntered"}
                                    onClick={(e) => {
                                        this.setState({paymentObject:this.state.hostedFormResponse})
                                    }}
                                />
                                <label className={"form-check-label"} htmlFor={"flexRadioEntered"}>
                                    {this.state.hostedFormResponse?.encryptedCardData.cardNumber}
                                </label>
                            </div>
                        </div>
                    }
                </div>
                }

            </div>
        )
    }

    useProviderInformation() {
        this.setState({ useProviderInfo: true })
        if (
            (this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state ||
                this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip ||
                this.state.ANETCustomerProfile.email) &&
            !this.state.useProviderInfo
        ) {
            sweetalert
                .fire({
                    title: "Replace Bill To Info?",
                    text: "NOTE: For security, credit card info will be cleared.",
                    showCloseButton: false,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Yes, replace",
                    denyButtonText: `No, go back`
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            changesMade: true,
                            useProviderInfo: true,
                            ANETCustomerProfile: {
                                customerProfileId: '',
                                description: '',
                                email: this.state.selectedFacility ? this.state.selectedFacility.ProviderEmail : '',
                                paymentProfiles: [
                                    {
                                        billTo: {
                                            address: this.state.selectedFacility ? this.state.selectedFacility.ProviderAddress : '',
                                            city: this.state.selectedFacility ? this.state.selectedFacility.ProviderCity : '',
                                            country: 'United States',
                                            firstName: this.state.selectedFacility ? this.state.selectedFacility.ProviderFirstName : '',
                                            lastName: this.state.selectedFacility ? this.state.selectedFacility.ProviderLastName : '',
                                            phoneNumber: this.state.selectedFacility ? this.state.selectedFacility.ProviderPhone : '',
                                            state: this.state.selectedFacility ? this.state.selectedFacility.ProviderState : '',
                                            zip: this.state.selectedFacility ? this.state.selectedFacility.ProviderZip : ''
                                        },
                                        customerPaymentProfileId: '',
                                        payment: {
                                            creditCard: {
                                                cardNumber: '',
                                                cardType: '',
                                                expirationDate: ''
                                            }
                                        }
                                    }
                                ],
                                profileType: 'regular',
                                shipToList: [
                                    {
                                        address: '',
                                        city: '',
                                        company: '',
                                        country: '',
                                        customerAddressId: '',
                                        faxNumber: '',
                                        firstName: '',
                                        lastName: '',
                                        phoneNumber: '',
                                        state: '',
                                        zip: ''
                                    }
                                ]
                            }
                        })
                        return
                    }
                    else if (result.isDenied) {
                        this.setState({ useProviderInfo: false })
                        return
                    }
                });
        } else if (!this.state.useProviderInfo) {
            this.setState({
                changesMade: true,
                useProviderInfo: true,
                ANETCustomerProfile: {
                    customerProfileId: '',
                    description: '',
                    email: this.state.selectedFacility ? this.state.selectedFacility.ProviderEmail : '',
                    paymentProfiles: [
                        {
                            billTo: {
                                address: this.state.selectedFacility ? this.state.selectedFacility.ProviderAddress : '',
                                city: this.state.selectedFacility ? this.state.selectedFacility.ProviderCity : '',
                                country: 'United States',
                                firstName: this.state.selectedFacility ? this.state.selectedFacility.ProviderFirstName : '',
                                lastName: this.state.selectedFacility ? this.state.selectedFacility.ProviderLastName : '',
                                phoneNumber: this.state.selectedFacility ? this.state.selectedFacility.ProviderPhone : '',
                                state: this.state.selectedFacility ? this.state.selectedFacility.ProviderState : '',
                                zip: this.state.selectedFacility ? this.state.selectedFacility.ProviderZip : ''
                            },
                            customerPaymentProfileId: '',
                            payment: {
                                creditCard: {
                                    cardNumber: '',
                                    cardType: '',
                                    expirationDate: ''
                                }
                            }
                        }
                    ],
                    profileType: 'regular',
                    shipToList: [
                        {
                            address: '',
                            city: '',
                            company: '',
                            country: '',
                            customerAddressId: '',
                            faxNumber: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            state: '',
                            zip: ''
                        }
                    ]
                }
            })
        }
        else {
            this.setState({ useProviderInfo: false })
            if (this.state.selectedFacility && this.state.selectedFacility.ANETCustomerProfileID) {
                this.setState({ showLoading: true })
                PaymentAPI.getCustomerProfile(this.state.selectedFacility.ANETCustomerProfileID, this.state.selectedFacility.ANETCustomerPaymentProfileID).then(data => {
                    this.setState({ ANETCustomerProfile: data.customerProfile.profile, showLoading: false });
                })
            }
            else {
                this.clearProfileState()
            }
            return
        }
    }

    verifyBillToInfo() {
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.firstName)
        ) {
            return sweetalert.fire({ icon: 'error', title: '', text: 'First Name is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.lastName)
        ) {
            return sweetalert.fire({ icon: 'error', title: '', text: 'Last Name is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.email) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.email) || 
            !isValidEmail(this.state.ANETCustomerProfile.email)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'Email is required and must be valid' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber) || 
            !isValidPhoneNumber(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.phoneNumber)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'Phone number is required and must be valid' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.address)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'Address is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.city)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'City is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.state)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'State is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.country)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'Country is required' })
        }
        if (isBlank(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip) || 
            isOnlyWhitespace(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip) || 
            !/^\d{5}(-\d{4})?(?!-)$/.test(this.state.ANETCustomerProfile.paymentProfiles[0].billTo.zip)
        ) {
                return sweetalert.fire({ icon: 'error', title: '', text: 'Zipcode is required and must be valid' })
        }
        sweetalert.fire({ icon: 'success', title: '', text: 'Bill To Info Successfully Validated' })

        this.setState({ billToInfoVerified: true }, ()=>{ 
            this.handleBillToSubmit(this.state.hostedFormResponse);
            // clear out hostedFormResponse to prevent Invalid OTS Token error
            // we will use card on profile to make payment 
            this.setState({hostedFormResponse: null}, ()=>{
                $('#flexRadio0').prop('checked',true).trigger("click");
            });
            //set new card to be used as payment
        })
    }

    updateBillToInfoOnly() {
        this.setState({ showLoading: true }, async () => {
            PaymentAPI.updateCustomerProfileWithoutCardInfo(this.state.selectedFacility.ANETMerchantID, this.state.selectedFacility.ANETCustomerProfileID, this.state.selectedFacility.ANETCustomerPaymentProfileID, this.state.ANETCustomerProfile).then(updatedResponse => {
                if (!updatedResponse.success) {
                    this.setState({ showLoading: false })
                    return sweetalert.fire({ icon: 'error', title: '', text: 'Error updating Bill To Profile. Please submit form and try again.' })
                }
                else {
                    this.setState({ changesMade: true, ANETCustomerProfile: updatedResponse.updatedCustomerProfile.profile, showLoading: false })
                    return sweetalert.fire({
                        icon: "success", title: '', text: 'Bill To Profile updated successfully'
                    });
                }
            })
        })
    }

    async handleBillToSubmit(response) {
        let description = `${this.state.selectedFacility.ProviderFirstName}-${this.state.selectedFacility.ProviderLastName}-${this.state.selectedFacility.FacilityName}`;
        this.setState({ showLoading: true }, async () => {
            if (this.state.selectedFacility.ANETCustomerProfileID) {
                this.updateBillToInfoOnly()
            } else {
                await PaymentAPI.createCustomerProfile(
                    this.state.ANETCustomerProfile, 
                    description, 
                    response
                ).then(createResponse => {
                    this.setState({ showLoading: false })
                    if (!createResponse.success) {
                        return sweetalert.fire({ icon: 'error', title: '', text: 'Error creating Bill To Profile. Please try again.' })
                    }
                    else {
                        try {
                            FacilityAPI.addANetCustomerProfileIDs({
                                facilityID: this.state.selectedFacility.ID,
                                aNetIDs: {
                                    merchantID: createResponse.createdCustomerProfile.profile.merchantCustomerId,
                                    customerProfileID: createResponse.createdCustomerProfile.profile.customerProfileId,
                                    paymentProfileID: createResponse.createdCustomerProfile.profile.paymentProfiles[0].customerPaymentProfileId
                                }
                            }).then(result => {
                                this.setState({
                                    changesMade: true,
                                    ANETCustomerProfile: createResponse.createdCustomerProfile.profile,
                                    selectedFacility: result.data
                                })
                                this.props.refreshSelectedFacility();
                                return sweetalert.fire({ icon: "success", title: '', text: 'Bill To Profile created successfully' });
                            });
                        } catch (e) {
                            console.error(e)
                            return sweetalert.fire({ icon: "error", title: '', text: 'Error updating Facility with ANet Profile.' });
                        }
                    }
                })
            }
        })
    }

    clearProfileState() {
        this.setState({
            billToInfoVerified: false,
            changesMade: false,
            useProviderInfo: false,
            ANETCustomerProfile: {
                customerProfileId: '',
                description: '',
                email: '',
                paymentProfiles: [
                    {
                        billTo: {
                            address: '',
                            city: '',
                            country: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            state: '',
                            zip: ''
                        },
                        customerPaymentProfileId: '',
                        payment: {
                            creditCard: {
                                cardNumber: '',
                                cardType: '',
                                expirationDate: ''
                            }
                        }

                    }
                ],
                profileType: 'regular',
                shipToList: [
                    {
                        address: '',
                        city: '',
                        company: '',
                        country: '',
                        customerAddressId: '',
                        faxNumber: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        state: '',
                        zip: ''
                    }
                ]
            }
        })
    }

  renderPriceDetails = () => {
    return (
      this.props.associatedPrices?.map((price) => {
        let jsx: JSX.Element;
        let isServicePrice = this.props.selectedServices?.some(ss => ss?.PricingIDs?.find(id => id === price.PricingID && price.FacilityID === this.state.selectedFacility.ID && price.ServiceID === ss.ID));

        switch (price.PricingType) {
          case 'Upgrade': // Pricing
            if (this.props.fromWalkin === false && isServicePrice) { // Samples Page Payment Form
              jsx = (<div className="row mr-0">
                <div className="col-sm-5">{price.Name}</div>
                <div className="col-sm-7 text-right">${price.Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
              </div>)
            }
            break;
          case 'Standard': // FacilityServicePricing
            if (this.props.fromWalkin === true && isServicePrice) { // Submission Page Payment Form
              // console.log(" submission form standard pricing")
              jsx = (<div className="row mr-0">
                <div className="col-sm-5">{price.Name}</div>
                <div className="col-sm-7 text-right">${price.Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
              </div>)
            }
            break;
        }
        return jsx;
      })
    )
  }
    render(){
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading} zIndex={100005} />
                <div className="modal fade form_modal" id={PaymentFormModal.ID} tabIndex={-1} role="dialog"
                    aria-labelledby="result_modal_label"
                    aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-lg" style={{maxWidth:"768px"}} role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-12 pt-2">
                                            <div className="card mb-2">
                                                <div className="card-header verlag-bold">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <h4>
                                                                Payment Information
                                                            </h4>
                                                        </div>
                                                        <div className="col-2">
                                                            <button style={{ outline: 'none' }} type="button" className="close pr-4"
                                                                aria-label="Close" onClick={() => {hideModal(PaymentFormModal.ID); }}>
                                                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className={"col-sm-12"}>
                                                        <div>
                                                            <h4>Pricing</h4>
                                                            { this.renderPriceDetails() }
                                                            <hr />
                                                        </div>
                                                  {/* Total */}
                                                        <div className="form-group row">
                                                            <label htmlFor={"totalAmountForServices"} className={"col-sm-4 col-form-label"}>Total</label>
                                                            <div className={"col-sm-8 m-0 input-group"}>
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text text-dark"
                                                                        style={{ "paddingLeft": "17.5px", "paddingRight": "17.5px" }}
                                                                    >$</span>
                                                                </div>
                                                                <input type={"text"}
                                                                    id={"totalAmountForServices"}
                                                                    className="form-control"
                                                                    style={{ "textAlign": "right" }}
                                                                    value={this.state.totalAmountForServices}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                  {/* Discount */}
                                                  { this.props.allowDiscountEntry ? 
                                                        <div className="form-group row">
                                                            <label htmlFor={"searchForDiscount"} className={"col-sm-4 col-form-label"}>Discount</label>
                                                            <div className="col-sm-8 input-group">
                                                                <div className="input-group-prepend">
                                                                    {
                                                                        !this.state.discountApplied && 
                                                                        <span className="input-group-text text-dark">
                                                                            <img src={"https://storage.googleapis.com/streamline-bucket/discount-badge.png"}
                                                                            alt="searchForDiscount"
                                                                            style={{ height: "20px" }}
                                                                            className=""
                                                                        /></span>
                                                                    }
                                                                    {
                                                                        !!this.state.discountApplied &&
                                                                        <span className="input-group-text text-dark">
                                                                            &nbsp;&minus;&nbsp;
                                                                        </span>
                                                                    }
                                                                </div>
                                                                {!this.state.discountAmount &&
                                                                    <input
                                                                        type={"text"}
                                                                        id={"DiscountCode"}
                                                                        className="form-control"
                                                                        style={{ "textAlign": "right" }}
                                                                        value={this.state.discountCode}
                                                                        autoComplete="off"
                                                                        onChange={(e) => {
                                                                            this.setState({ discountCode: e.target.value })
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            this.validateDiscountCode(e.target.value)
                                                                        }}
                                                                        maxLength={24}
                                                                        placeholder="Discount Code"
                                                                    />
                                                                }
                                                                {this.state.discountAmount &&
                                                                    <input type={"text"}
                                                                        id={"discountAmount"}
                                                                        className="form-control"
                                                                        style={{ "textAlign": "right" }}
                                                                        value={this.state.discountAmount}
                                                                        autoComplete="off"
                                                                        readOnly
                                                                    />
                                                                }
                                                            </div>
                                                        </div> :
                                                  <></>}
                                                  {/* Payment */}
                                                        <div className="form-group row">
                                                            <label htmlFor={"paymentAmount"} className={"col-sm-4 col-form-label"}>Payment</label>
                                                            <div className={"col-sm-8 m-0 input-group"}>
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text text-dark"
                                                                        style={{ "paddingLeft": "17.5px", "paddingRight": "17.5px" }}
                                                                    >$</span>
                                                                </div>
                                                                <input type={"text"} className="form-control" id={"paymentAmount"}
                                                                    value={this.state.paymentAmount}
                                                                    style={{ "textAlign": "right" }}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                          {/* Payment Profile Option */}
                                                <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                                                    <div className="d-flex justify-content-around" style={{gap:"3px"}}>
                                                        {this.displayPaymentProfileOptions()}

                                                        {   
                                                            !!this.state.hostedFormResponse &&
                                                            <div className="" id="entered_payment_options">
                                                                <div className={""}>Entered:</div>
                                                                <div className={"form-check"}>
                                                                    <input
                                                                        className={"form-check-input"}
                                                                        type="radio" name={"flexRadioGroup"} id={"flexRadioEntered"}
                                                                        onChange={(e) => {
                                                                            this.setState({paymentObject:this.state.hostedFormResponse})
                                                                            // this.props.handlePaymentInfo(this.state.hostedFormResponse, this.state.paymentAmount, this.buildLineItems())
                                                                        }}
                                                                    />
                                                                    <label className={"form-check-label"} htmlFor={"flexRadioEntered"}>
                                                                        {this.state.hostedFormResponse?.encryptedCardData.cardNumber}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        } 
                                                        <div>
                                                            <div className={'col-sm-12'}>
                                                                <HostedForm
                                                                    authData={authData}
                                                                    onSubmit={(hostedFormResponse) => { this.handleHostedFormResponse(hostedFormResponse) }}
                                                                    environment={aNetEnv}
                                                                    billingAddressOptions={{ show: true, required: true}}
                                                                    buttonText={"Enter Card Information"}
                                                                    formButtonText={`Confirm Card Information`}
                                                                    formHeaderText={`Payment for Services`}
                                                                    buttonClassName={"btn btn-primary"}
                                                                    paymentOptions={{ showCreditCard: true, showBankAccount: false }}
                                                                    buttonStyle={{}}
                                                                    errorTextStyle={{}}
                                                                    errorTextClassName={""}
                                                                    containerStyle={{}}
                                                                    containerClassName={""}
                                                                />
                                                                <div className={"text-center mt-2"}>
                                                                    <button className={"btn btn-success"}
                                                                        onClick={(e) => { 
                                                                            if(this.state.paymentObject === null && (
                                                                               this.state.paymentAmount != 0.00 || this.state.paymentAmount != 0)
                                                                            ){ 
                                                                                return sweetalert.fire({icon: 'error', title: '', text: "Please select a Payment Type."})
                                                                            }
                                                                            if(this.state.showPaymentProfileInfo && !this.state.billToInfoVerified){ 
                                                                                return sweetalert.fire({icon: 'error', title: '', text: "Card Information cannot be saved without verifying billing information"})
                                                                            }

                                                                            this.props.handlePaymentInfo(this.state.paymentObject, this.state.paymentAmount, this.buildLineItems())
                                                                            hideModal(PaymentFormModal.ID);
                                                                        }}
                                                                        disabled={ (!this.state.hostedFormResponse && !this.state.ANETCustomerProfile) }
                                                                    >Confirm
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                {
                    this.state.showPaymentProfileInfo &&
                    this.state.ANETCustomerProfile &&
                    this.state.ANETCustomerProfile.customerProfileId === "" &&
                    this.state.ANETCustomerProfile.paymentProfiles &&
                    this.state.ANETCustomerProfile.paymentProfiles.length > 0 &&
                    this.state.ANETCustomerProfile.paymentProfiles.map((val, index) => {
                        return (
                            <div>
                                <div className={"form-group row"}>
                                    <div className={"col-12 pr-0"} data-toggle={'tooltip'} data-placement={'top'} title={'BillTo'}>
                                        <label htmlFor={'BillTo'} style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                            {index > 1 ? `Bill To Information: ${index + 1}` : 'Bill To Information'}
                                        </label>
                                        <div className="form-check" style={{ paddingTop: '0.25rem' }}>
                                            <input className="form-check-input" type="checkbox"
                                                checked={this.state.useProviderInfo ? true : false}
                                                id="useProviderInfoCheck"
                                                onChange={() => this.useProviderInformation()}
                                            />
                                            <label className="form-check-label" htmlFor="useProviderInfoCheck">
                                                Use Provider Information
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        {buildRow("First Name",
                                            <input
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={`BillToFirstName${index + 1}`}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {

                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                firstName: e.target.value
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={val.billTo.firstName}
                                            />, 'Card holder first name'
                                        )}
                                        {buildRow("Last Name",
                                            <input
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={`BillToLastName${index + 1}`}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                lastName: e.target.value
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={val.billTo.lastName}
                                            />, 'Card holder last name'
                                        )}
                                        {buildRow("Email",
                                            <input className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={"CardHolderEmail"}
                                                onChange={(e) => {
                                                    this.setState((prevState) => ({
                                                        changesMade: true,
                                                        billToInfoVerified: false,
                                                        ANETCustomerProfile: {
                                                            ...prevState.ANETCustomerProfile,
                                                            email: e.target.value
                                                        }
                                                    }))
                                                }}
                                                value={this.state.ANETCustomerProfile.email}
                                            />
                                            , 'Card holder email'
                                        )}
                                        {buildRow("Phone Number",
                                            <PhoneInput
                                                name={`BillToPhoneNumber${index + 1}`}
                                                id={'Phone Number'}
                                                placeholder="Enter phone number"
                                                value={val.billTo.phoneNumber}
                                                defaultCountry="US"
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                phoneNumber: e
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}

                                            />, 'Card holder phone number'
                                        )}
                                        {buildRow("Address",
                                            <input
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={`BillToAddress${index + 1}`}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                address: e.target.value
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={val.billTo.address}
                                            />, 'Card holder address'
                                        )}
                                        {buildRow("City",
                                            <input
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={`BillToCity${index + 1}`}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                city: e.target.value
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={val.billTo.city}
                                            />, 'Card holder city'
                                        )}

                                    </div>
                                    <div className="col-12 col-lg-6">
                                        {buildRow("State",
                                            <Select
                                                name={`BillToState${index + 1}`}
                                                isSearchable={true}
                                                placeholder={"Please Select..."}
                                                noOptionsMessage={() => "No option"}
                                                value={getLabel(val.billTo.state, this.props.states)}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                state: e.value
                                                            }
                                                        }
                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                className={"state_select"}
                                                options={this.props.states}
                                            />, 'Card holder state'
                                        )}
                                        {buildRow("Country",
                                            <Select
                                                name={`BillToCountry${index + 1}`}
                                                isSearchable={true}
                                                placeholder={"Please Select..."}
                                                noOptionsMessage={() => "No option"}
                                                value={getLabel(val.billTo.country, this.props.countries)}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                country: e.value
                                                            }
                                                        }
                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                className={"state_select"}
                                                options={this.props.countries}
                                            />, 'Card holder country'
                                        )}

                                        {buildRow("Zipcode",
                                            <input className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={`BillToZipcode${index + 1}`}
                                                onChange={(e) => {
                                                    this.setState((prevState) => {
                                                        const updatedPaymentProfiles = [...prevState.ANETCustomerProfile.paymentProfiles];
                                                        updatedPaymentProfiles[index] = {
                                                            ...updatedPaymentProfiles[index],
                                                            billTo: {
                                                                ...updatedPaymentProfiles[index].billTo,
                                                                zip: e.target.value
                                                            }
                                                        }

                                                        return {
                                                            changesMade: true,
                                                            billToInfoVerified: false,
                                                            ANETCustomerProfile: {
                                                                ...prevState.ANETCustomerProfile,
                                                                paymentProfiles: updatedPaymentProfiles
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={val.billTo.zip}
                                            />
                                            , 'Card holder zipcode'
                                        )}
                                        {buildRow("",
                                            <div className="row">
                                                <div className={!this.state.billToInfoVerified ? "col-12" : "d-none"}>
                                                    <button className="btn btn-sm btn-success w-100" onClick={() => this.verifyBillToInfo()} onKeyDown={()=>this.verifyBillToInfo()}>
                                                        Validate Bill To Info
                                                    </button>
                                                </div>
                                                {/* NOTE: Update functionality exists only on the Facility */}
                                                {/* <div className={
                                                    //bill to not verified
                                                    !this.state.billToInfoVerified ? "d-none" :
                                                        //bill to verified, no card saved, show Add Card button
                                                        this.state.billToInfoVerified && !this.state.selectedFacility?.ANETCustomerProfileID && !this.state.ANETCustomerProfile.customerProfileId ? "col-12" :
                                                            //bill to verified, first time card added, do not show update
                                                            this.state.billToInfoVerified && !this.state.selectedFacility?.ANETCustomerProfileID && this.state.ANETCustomerProfile.customerProfileId ? "d-none" :
                                                                //bill to verified, not first time
                                                                "col-12 col-md-6"}>
                                                                <HostedForm
                                                                    buttonText={this.state.selectedFacility?.ANETCustomerProfileID ? "Update Card" : "Add Card"}
                                                                    buttonClassName="btn-btn-small btn-success w-100"
                                                                    buttonStyle={{ border: 'none', borderRadius: '0.25rem', padding: '0.45rem' }}
                                                                    formButtonText={"Save"}
                                                                    formHeaderText={"Card Information"}
                                                                    billingAddressOptions={{ show: false, required: false }}
                                                                    errorTextStyle={{ display: 'none' }}
                                                                    authData={authData}
                                                                    onSubmit={(e) => {
                                                                        this.handleBillToSubmit(e)
                                                                    }}
                                                                />
                                                </div>
                                                <div className={
                                                    this.state.billToInfoVerified && this.state.selectedFacility?.ANETCustomerProfileID ? "col-12 pt-1 pt-md-0 col-md-6 text-right" : "d-none"}>
                                                    <button className="btn btn-sm btn-success w-100" onClick={() => this.updateInfoOnly()} onKeyDown={()=>this.updateInfoOnly()}>
                                                        Update Info
                                                    </button>
                                                </div> */}
                                            </div>
                                        )}
                                        {buildRow("Card Number",
                                                    <input className={"form-control"}
                                                        autoComplete={"off"}
                                                        type={"search"}
                                                        name={`BillToCardNumber${index + 1}`}
                                                        readOnly={true}
                                                        value={val.payment.creditCard.cardNumber}
                                                    />
                                                    , 'Credit Card Number'
                                        )}
                                        {buildRow("Card Type",
                                            <input className={"form-control"}
                                                autoComplete={"off"}
                                                readOnly={true}
                                                type={"search"}
                                                name={`BillToCardType${index + 1}`}
                                                value={val.payment.creditCard.cardType === 'AmericanExpress' ? 'American Express' : val.payment.creditCard.cardType}
                                            />
                                            , 'Card Type'
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}