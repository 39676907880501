import NetworkUtil from "./NetworkUtil";
import {AlertAudit} from "../types/AlertAudit";
import {Batches} from "../types/Batches";
import {CommunicationTemplate} from "../types/CommunicationTemplate";
import Facility from "../types/Facility";
import {PodStat} from "../types/PodSched";
import {Sample} from "../types/Sample";
import {Service} from "../types/Service";
import {CustomFieldType} from "../types/CustomFieldType";
import LabInformation from "../types/LabInfo";
import Insurance from "../types/Insurance";
import TestType from "../types/TestType";
import { Patient } from "../types/Patient";
import {PasswordConfigurationValues } from "../types/PasswordConfig";
import Pricing, { FacilityPriceAuditLog } from "../types/Price";
import Discount from "../types/Discount";

export default class AuditLogsAPI {
    public static async alertAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:AlertAudit[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/alertAuditLogs`, filter);
    }

    public static async batchAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Batches[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/batchAuditLogs`, filter);
    }

    public static async commTemplateAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:CommunicationTemplate[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/commTemplateAuditLogs`, filter);
    }

    public static async facilityAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Facility[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/facilityAuditLogs`, filter);
    }

    public static async podAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:PodStat[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/podAuditLogs`, filter);
    }

    public static async sampleAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Sample[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/sampleAuditLogs`, filter);
    }

    public static async serviceAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Service[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/serviceAuditLogs`, filter);
    }

    public static async resultAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Service[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/resultAuditLogs`, filter);
    }

    public static async labInfoAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean,data:LabInformation[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/labInformationAuditLogs`, filter);
    }

    public static async customFieldAuditLogs(filter: {filter:{DisplayName?}}):Promise<{success:boolean, data:CustomFieldType[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/customFieldAuditLogs`, filter);
    }

    public static async insuranceAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Insurance[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/insuranceAuditLogs`, filter);
    }

    public static async testsAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:TestType[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/testsAuditLogs`, filter);
    }

    public static async patientsAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Patient[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/patientsAuditLogs`, filter);
    }

    public static async pswdConfigAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean,data:PasswordConfigurationValues[]}>{
        return await NetworkUtil.makePost(`/api/admin/audit/pswdReqAuditLogs`, filter);
    }

    public static async pricingAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean,data:Pricing[]}>{
        return await NetworkUtil.makePost(`/api/admin/audit/pricingAuditLogs`, filter);
    }

    public static async discountAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean,data:Discount[]}>{
        return await NetworkUtil.makePost(`/api/admin/audit/discountAuditLogs`, filter);
    }

    public static async fileMgmtAuditLogs(filter: {filter:{ProductID, DocumentName?}}):Promise<{success:boolean,data:any}>{
        return await NetworkUtil.makePost(`/api/admin/audit/fileMgmtAuditLogs`, filter);
    }

    public static async bannerMgmtAuditLogs(filter: {filter:{BannerID}}):Promise<{success:boolean,data:any}>{
        return await NetworkUtil.makePost(`/api/admin/audit/bannerMgmtAuditLogs`, filter);
    }

    public static async facilityPricingAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:FacilityPriceAuditLog[]}>{
      return NetworkUtil.makePost(`/api/admin/audit/facilityPricingAuditLogs`, filter);
  } 
}